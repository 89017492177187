import Sky from 'react-sky';

function App() {
  return (
    <div className="App">
    <Sky
      images={{
        0: "https://cdn.discordapp.com/attachments/86673415238344704/859597809203675196/coleman.png"
      }}
      how={500}
      size={'100px'}
      background={'purple'}
    />
    </div>
  );
}

export default App;
